import { Box, Heading, Text, Button } from '@chakra-ui/react';
import Page from "layouts/Page";
import Link from "next/link";

export default function NotFound() {
  return (
    <Page>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, brand.400, brand.600)"
          backgroundClip="text">
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page introuvable
        </Text>
        <Text color={'gray.500'} mb={6}>
          La page que vous recherchez ne semble pas exister
        </Text>

        <Link href="/">
          <Button
            colorScheme="brand"
            color="white"
            variant="solid">
            Accueil
          </Button>
        </Link>
      </Box>
    </Page>
  );
}